.technology-stack__container > .slick-initialized > .slick-list > .slick-track {
  width: 3000px !important;
}

.technology-stack__container
  > .slick-initialized
  > .slick-list
  > .slick-track
  > .slick-slide {
  margin: 0;
}

.technology-stack__container
  > .slick-initialized
  > .slick-list
  > .slick-track
  > .slick-slide:last-child
  .card--technology {
  width: 100% !important;
}

@media screen and (max-width: 600px) {
  .technology-stack--database
    > .technology-stack__container
    > .slick-initialized
    > .slick-list
    > .slick-track {
    width: auto !important;
  }
}

@media screen and (min-width: 1600px) {
  .technology-stack__container
    > .slick-initialized
    > .slick-list
    > .slick-track {
    width: 2000px !important;
  }
}
