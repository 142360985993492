.service {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.service__paragraph {
  background: transparent;
  width: 376px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  color: var(--white-color-second);
  display: flex;
  flex-direction: column;
  gap: 24px;

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;

    &:nth-child(2) {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  ul {
    display: grid;
    gap: 8px;
  }

  li {
    display: flex;
    gap: 9px;
  }
}

@media screen and (max-width: 600px) {
  .service {
    flex-direction: column;
  }

  .service__paragraph {
    width: auto;

    p {
      font-size: 16px;
      line-height: 24px;

      &:nth-child(2) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .service__codes {
    overflow-x: scroll;
    border-radius: 12px;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: rgba(60, 60, 60, 0.8);
    padding: 24px;
    backdrop-filter: blur(24px);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 146px;
      background: linear-gradient(270deg, #3c3c3c 0%, rgba(60, 60, 60, 0) 100%);
      border-radius: 12px;
    }
  }
}
