.background-text {
  text-align: center;
  position: fixed;
  top: 144px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  display: none;

  &.disable {
    display: block;
  }
}

.background-text__item {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 900;
  font-size: 136px;
  line-height: 166px;
  text-transform: uppercase;
  color: var(--white-color-main);
  text-align: center;
  background: transparent;

  &:nth-child(even) {
    transform: translateX(-300%);
  }
  &:nth-child(odd) {
    transform: translateX(300%);
  }
}

@media screen and (max-width: 600px) {
  .background-text {
    display: none;
    flex-direction: column;
    top: 0;
    justify-content: center;
    align-items: center;

    &.disable {
      display: flex;
    }
  }

  .background-text__item {
    font-size: 32px;
    line-height: 44px;
  }
}
