.custom-mouse {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--white-color-main);
  border-radius: 50%;
  mix-blend-mode: difference;
  z-index: 111001;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  .custom-mouse {
    display: none;
  }
}
