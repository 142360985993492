.loader {
  position: fixed;
  background: url("../../assets/images/background.svg");
  background-color: var(--bg-color);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 111111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--white-color-main);
  font-family: "Orbitron";
  font-style: normal;

  &--title {
    font-weight: 900;
    font-size: 32px;
    line-height: 46px;
  }

  &--process {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

.loader__fake-data {
  display: none !important;
}
