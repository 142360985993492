.header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-color-main);
  opacity: 0;
  transition: 1s ease-out all;

  &--visible {
    opacity: 1;
  }
}

.header__menus {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header__menus-minimized {
  display: flex;
  position: fixed;
  justify-content: space-between;
  top: 48px;
  right: 60px;
  left: 60px;
  z-index: 11100;

  &--visible {
    display: flex !important;
    opacity: 1 !important;
    transform: translate(0px, 0px) !important;
  }
}

.header__menus-box {
  display: flex;
  gap: 20px;
}

.header__menus-box > li > a {
  font-size: 16px;
  line-height: 24px;
  padding: 20px 20px 20px 0;
}

.header__hamburger {
  width: 48px;
  height: 48px;
  background: var(--white-color-main);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--background {
    background: transparent;

    &:active,
    &:focus,
    &:hover {
      background: var(--white-color-main);
      animation: contact 1s ease-out 1;

      .header__hamburger-line-lower,
      .header__hamburger-line-upper {
        stroke: var(--bg-color);
      }
    }
  }

  &:hover {
    animation: contact 1s ease-out 1;
  }
}

.header__hamburger-wrapper--display {
  display: flex;
}

.header__hamburger-line-lower,
.header__hamburger-line-upper {
  fill: none;
  stroke: var(--bg-color);
  stroke-linecap: round;

  &--color {
    stroke: var(--white-color-main);
  }
}

.header__contact {
  &:hover,
  &.active {
    .header__contact-link {
      animation: contact 1s ease-out 1;
      background-color: var(--white-color-main);
      color: var(--bg-color);
    }
  }

  &--display {
    display: none;
  }
}

.header__contact-link {
  padding: 12px 16px;
  background: #282828;
  border-radius: 12px;
  transition: all 0.3s ease-out;
  font-size: 16px;
  line-height: 24px;

  &--minimized {
    position: absolute;
    top: 0;
    right: 0;
  }
}

// press and hold animation
@keyframes contact {
  0% {
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 hsl(0deg 0% 100% / 80%);
  }

  100% {
    box-shadow: 0 0 0 0.6em transparent, 0 0 0 0.8em hsl(0deg 0% 100% / 0%);
  }
}

@media screen and (max-width: 600px) {
  .header__contact {
    padding: 0;
    top: 24px;
    right: 24px;
    width: 48px;
    height: 48px;

    &.active,
    &:hover {
      background: transparent;

      .header__contact-icon > svg {
        rect {
          fill: var(--white-color-main);
        }
        path {
          fill: var(--bg-color);
        }
      }
    }
  }

  .header__hamburger {
    top: 24px;
  }

  .header__menus-minimized {
    top: 24px;
    right: 24px;
    left: 24px;
  }
}
