.menu {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: #282828;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  padding-left: 60px;
  align-items: center;
  opacity: 0;
  visibility: hidden;

  &--visible {
    opacity: 1;
    visibility: visible;
  }
}

.menu__wrapper {
  overflow: hidden;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.menu__option-wrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 32px;
  transition: all 0.7s ease-out;

  &:hover,
  &.active {
    .menu__option {
      color: var(--white-color-main);
    }

    .menu__option--color {
      visibility: visible;
      opacity: 1;
    }
  }
}

.menu__option,
.menu__option--color {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 900;
  font-size: 96px;
  line-height: 132px;
  text-transform: uppercase;
  color: rgba(251, 249, 235, 0.2);
  transition: all 0.9s ease-out;
}

.menu__option--color {
  color: #ffff83;
  width: 12vw;
  visibility: hidden;
  opacity: 0;
  transition: all 0.9s ease-out;
}

@media screen and (max-width: 600px) {
  .menu {
    padding-left: 16px;
    align-items: flex-start;
    padding-top: 96px;
  }

  .menu__option,
  .menu__option--color {
    font-size: 30px;
    line-height: 44px;
  }

  .menu__wrapper,
  .menu__option-wrapper {
    margin: 0;
    gap: 16px;
  }
}
