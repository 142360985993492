.process__cards {
  margin-top: 48px;
}

.process__counter {
  position: absolute;
  bottom: 40px;
  right: 0;
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: right;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--white-color-second);
  display: flex;
  width: 100%;
  justify-content: center;

  &--all {
    opacity: 0.5;
  }
}

.process__counter-box {
  position: relative;
}

.process__counter-box > div {
  position: absolute;
  right: 0;
}
