.key-feature {
  display: flex;
  height: 100vh;
  position: relative;
}

.key-feature__paragraph {
  width: 376px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--white-color-second);

    &:nth-child(2) {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.key-feature__cards {
  position: absolute;
  right: 0;
}

.key-feature__counter {
  position: absolute;
  bottom: 210px;
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: right;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--white-color-second);
  display: flex;
  width: 100%;
  justify-content: center;

  &--all {
    opacity: 0.5;
  }
}

.key-feature__counter-box {
  position: relative;
}

.key-feature__counter-box > div {
  position: absolute;
  right: 0;
}
