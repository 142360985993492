@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Orbitron:wght@600;700;900&family=Rubik:wght@400;500;600&display=swap");

:root {
  --white-color-main: #fbf9eb;
  --white-color-second: #f9f8f0;
  --bg-color: #1d1d1d;
  --bg-second-color: #282828;
}

html,
*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: text;
  cursor: none;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
  list-style: none;
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-color);
}

::-webkit-scrollbar {
  display: none;
}

progress {
  width: 100%;
  border-radius: 2px;
  height: 2px;
  transition: all 0.3s ease;

  &::-webkit-progress-bar {
    background: #4a4a4a;
  }

  &::-webkit-progress-value {
    background: rgba(255, 255, 255, 0.4);
    background-attachment: fixed;
  }
}

.slick-list {
  overflow: unset !important;
}

.slick-slide {
  margin-right: 16px;
}

@media screen and (max-width: 600px) {
  .slick-slide {
    margin-right: 8px;
  }
}
