.technology {
  background: rgba(60, 60, 60, 0.8);
  backdrop-filter: blur(24px);
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  padding: 23px 23px 5px 23px;
  overflow: hidden;
  position: relative;
}

.technology__cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.technology__cards-button {
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: transparent;
  padding: 16px;
  border-radius: 8px;
  height: 52px;
  color: var(--white-color-main);
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease all;
  outline: none;
  white-space: nowrap;

  &:active,
  &:hover,
  &:focus,
  &:focus-visible,
  &--active {
    color: var(--bg-color);
    background: var(--white-color-main);
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 16px;
    border-radius: 8px;
    height: 52px;
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex !important;
    align-items: center;
    transition: 0.3s ease all;
    outline: none;
  }
}

.technology__progress-layout {
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
  width: auto;
}

@media screen and (max-width: 600px) {
  .technology {
    padding: 24px 24px 36px 24px;
  }
}
