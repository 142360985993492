.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo__text {
  font-family: "Orbitron";
  font-style: normal;
  color: transparent;
  -webkit-text-stroke: var(--white-color-main);
  position: fixed;
  z-index: 1;
}

.logo__pressHold {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 132px;
  overflow: hidden;
  font-family: "Orbitron";
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--white-color-main);
  width: 72px;
  height: 72px;
  border: 1px solid var(--white-color-main);
  border-radius: 9999px;
  transition: 0.3s ease-out;

  &:hover {
    color: var(--bg-color);
    background-color: var(--white-color-main);
    animation: pressHold 1s ease-out 1;
  }

  &:active {
    animation: pressHold 1s ease-out infinite;
  }

  &--invisible {
    display: none;
  }
}

.logo__pressHold-button {
  z-index: 9999;
}

.logo__scroll {
  position: absolute;
  bottom: 68px;
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-color-main);
  opacity: 0;
  transition: 0.2s ease all;
  visibility: hidden;
  background: transparent;

  &--visible {
    transform: translateY(-10px);
    opacity: 1;
    visibility: visible;
  }
}

.logo__scroll-box {
  display: flex;
  align-items: center;
  gap: 20px;
}

// press and hold animation
@keyframes pressHold {
  0% {
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 hsl(0deg 0% 100% / 80%);
  }

  100% {
    box-shadow: 0 0 0 0.6em transparent, 0 0 0 0.8em hsl(0deg 0% 100% / 0%);
  }
}

@media screen and (max-width: 600px) {
  .logo__pressHold {
    bottom: 65px;
  }

  .logo__text {
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: -1;
  }
}
