.container {
  padding: 48px;
  gap: 48px;
  background: #282828;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px 24px 0px 0px;
  height: 770px;
  overflow: hidden;
  margin-top: 144px;

  &--contact {
    height: 85vh;
  }
}

.container__title {
  display: flex;
  align-items: center;
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 900;
  font-size: 96px;
  line-height: 132px;
  text-transform: uppercase;
  color: var(--white-color-main);
  min-width: 100%;
}

.container__technology-progress {
  margin-top: 4px;
}

@media screen and (max-width: 600px) {
  .container {
    padding: 24px;
    gap: 16px;
    margin-top: 96px;
    height: 725px;

    &--contact {
      height: 89vh;
    }
  }

  .container__title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.01em;
  }
}
