.contact {
  display: flex;
  gap: 140px;
}

.contact__form {
  background: rgba(60, 60, 60, 0.8);
  backdrop-filter: blur(24px);
  padding: 32px;
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 712px;
  overflow: hidden;
}

.contact__reach {
  display: grid;
  gap: 32px;
  height: 140px;
}

.contact__reach-email,
.contact__reach-location {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contact__reach-text,
a {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--white-color-second);
  text-decoration: none;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.contact__form-name {
  display: flex;
  gap: 16px;
}

.contact__form-email {
  display: flex;
  gap: 16px;
}

.contact__form-input {
  padding: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: #383838 !important;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  width: 100% !important;
  outline: none;
  color: var(--white-color-second) !important;

  &::placeholder {
    opacity: 0;
  }
}

.contact__form-textarea {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  position: relative;
  padding: 0;
  height: 148px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact__form-input.textarea {
  height: 60px !important;
  border: none;
  overflow-y: scroll;
  resize: none;
  padding: 0 8px 0 16px !important;
  margin-top: 16px;
  width: calc(100% - 8px) !important;

  &--with-scrollBar::-webkit-scrollbar {
    display: block;
    width: 1px;
  }

  &--with-scrollBar::-webkit-scrollbar-track {
    background: var(--bg-second-color);
    border-radius: 1px;
  }

  &--with-scrollBar::-webkit-scrollbar-thumb {
    background: var(--white-color-second);
    border-radius: 1px;
  }
}

.contact__form-label {
  position: relative;
  width: 100%;
}

.contact__form-label > span {
  position: absolute;
  top: 5px;
  left: 16px;
  transform: translateY(10px);
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--white-color-main);
  transition: all 0.3s ease;
  opacity: 0.2;
}

.contact__form-label:focus-within > span,
.contact__form-input:not(:placeholder-shown) + span {
  opacity: 1;
  padding: 1px;
  background-color: #383838;
  transform: translateY(-16px);

  .contact__optional {
    display: none;
  }
}

.contact__form-attach-block {
  margin-left: 16px;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
}

.contact__form-attach {
  position: relative;
}

.contact__form-attach-input {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 50px;
  height: 30px;
  opacity: 0;
}

.contact__uploaded-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 8px;
  background-color: var(--white-color-main);
  width: max-content;
  height: 24px;
  border-radius: 4px;
  gap: 16px;
}

.contact__uploaded-file-name {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--bg-second-color);
  white-space: nowrap;
}

.contact__uploaded-file-size {
  opacity: 0.5;
}

.contact__form-button {
  border: none;
  background: #ffff83;
  opacity: 0.5;
  border-radius: 8px;
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--bg-color);
  text-align: center;
  height: 42px;
  transition: all 0.3s;

  &--active {
    opacity: 1;
  }
}

.contact__form-input:-webkit-autofill,
.contact__form-input:-webkit-autofill:hover,
.contact__form-input:-webkit-autofill:focus,
.contact__form-input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--white-color-main) !important;
  caret-color: var(--white-color-main) !important;
  background-color: var(--white-color-main);
}

@media screen and (max-width: 600px) {
  .contact {
    flex-direction: column-reverse;
    gap: 24px;
  }

  .contact__reach {
    gap: 16px;
  }

  .contact__form {
    width: 280px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.4);
  }

  .contact__form-name,
  .contact__form-email {
    flex-direction: column;
  }

  .contact__reach-icon > svg {
    width: 32px;
    height: 32px;
  }

  .contact__reach-email {
    gap: 16px;
  }

  .contact__reach-text {
    font-size: 16px;
    line-height: 24px;

    span {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .contact__form-attach-block {
    flex-direction: row;
  }

  .contact__form-textarea {
    height: 96px !important;
  }

  .contact__form-input.textarea {
    height: 40px !important;
  }
}
