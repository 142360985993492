.services-for-offer {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.services-for-offer__paragraph {
  color: var(--white-color-second);
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.services-for-offer__cards
  > .slick-initialized
  > .slick-list
  > .slick-track
  > .slick-slide {
  margin: 0;
}

.services-for-offer__cards
  > .slick-initialized
  > .slick-list
  > .slick-track
  > .slick-slide:last-child
  .services-for-offer__box {
  width: 100% !important;
}

@media screen and (max-width: 600px) {
  .services-for-offer {
    gap: 16px;
  }

  .services-for-offer__paragraph {
    font-size: 16px;
    line-height: 24px;
  }
}
