.card {
  background: rgba(60, 60, 60, 0.8);
  backdrop-filter: blur(24px);
  border-radius: 16px;
  width: 520px;
  height: 210px;
  padding: 48px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 48px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-image-slice: 1;
  overflow: hidden;
  position: relative;

  &--service {
    width: 774px !important;
    height: 274px;
    gap: 56px;
    padding: 56px;
  }

  &--process {
    position: absolute;
    align-items: flex-start;
    padding: 40px;
    gap: 40px;
  }

  &--technology {
    width: 178px !important;
    height: 96px;
    gap: 0;
    display: block !important;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    padding: 16px;
    margin-bottom: 14px;
  }
}

.card__icon {
  width: 100%;
  max-width: 96px;
  min-height: 96px;

  &--size {
    min-width: 104px;
    max-width: 104px;
  }

  &--technology {
    max-width: 32px;
    min-height: 32px;
  }
}

.card__text {
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card__title {
  background: transparent;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: var(--white-color-main);

  &--technology {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 143px;
    text-align: center;
  }
}

.card__paragraph {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-color-main);
}

@media screen and (max-width: 600px) {
  .card {
    width: 256px;
    height: auto;
    flex-direction: column;
    padding: 24px;
    gap: 0;
    border-radius: 12px;

    &--service {
      width: 256px !important;
      height: 332px;
      gap: 24px;
      padding: 24px;

      .card__text {
        padding-top: 0;
      }
    }

    &--process {
      width: 256px;
      height: 450px;
      gap: 36px;
      align-items: center;

      .card__text {
        padding-top: 0;
      }
    }

    &--technology {
      width: 175px !important;
      height: auto;
      gap: 8px;
      padding: 16px;
      border-radius: 8px;
      margin-bottom: 6px;

      .card__text {
        padding-top: 0;
      }
    }
  }

  .card__text {
    gap: 8px;
    padding-top: 24px;
  }

  .card__title {
    font-size: 16px;
    line-height: 24px;

    &--technology {
      font-size: 14px;
      line-height: 22px;
      overflow: inherit;
      text-overflow: inherit;
      white-space: inherit;
      width: auto;
    }
  }

  .card__paragraph {
    font-size: 14px;
    line-height: 22px;

    &--service {
      height: 132px;
      overflow-y: scroll;
    }
  }
}

@media screen and (min-width: 1600px) {
  .card {
    &--service,
    &--technology {
      margin-right: 16px;
    }
  }
}
