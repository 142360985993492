.app {
  padding-top: 40px;
  padding-left: 60px;
  padding-right: 60px;
  height: auto;

  &--overflow {
    height: 100vh;
    overflow: hidden;
  }
}

.app__background {
  background: url("./assets/images/background.svg");
  z-index: -10;
  position: fixed;
  width: 100%;
  height: 100vh;
}

.header:hover .custom-mouse {
  width: 70px !important;
  height: 70px !important;
}

.app__container {
  margin-top: 3100px;
}

.app__page {
  position: -webkit-sticky;
  position: sticky;
  top: 144px;
}

.app__page:nth-child(2),
.app__page:nth-child(3) {
  margin-top: 2000px;
}

.app__page:nth-child(5) {
  margin-top: 2200px;
}

@media screen and (max-width: 600px) {
  .app {
    padding-left: 24px;
    padding-right: 24px;
  }

  .app__container {
    margin-top: 3500px;
  }

  .app__page {
    top: 96px;
  }

  .app__page:nth-child(3) {
    margin-top: 2600px;
  }
}
